<template>
  <h2 class="section-title part-component" ref="title">
    <span v-if="partNo">{{ sub }}</span>
    <br />
    {{ partTitle }}
  </h2>
</template>

<script>
import { partComponent } from "@/mixins/core.js";

export default {
  name: "SectionTitle",
  mixins: [partComponent],
  props: ["partNo", "partTitle"],
  data() {
    return {};
  },
  computed: {
    sub() {
      if (!this.partNo) return false;
      return Number.isInteger(this.partNo)
        ? `Part ${this.partNo}`
        : this.partNo;
    },
  },
  methods: {
    createTimeline() {
      const { title } = this.$refs;

      const tl = new TimelineMax({
        paused: true,
      });

      let split = new SplitText(title, {
        type: "words",
        position: "relative",
      });

      tl.staggerFrom(
        split.words,
        0.7,
        {
          x: "-50",
          autoAlpha: 0,
          ease: Power2.easeOut,
        },
        0.1,
        0
      );

      this.timeline = tl;
    },
  },
};
</script>

<style>
.section-title {
  z-index: 4;
  font-size: 4.5rem;
  font-weight: normal;
  max-width: 6.389em;
  line-height: 0.85;
  margin: 0.378em 0 0.489em;
  text-shadow: var(--title-shadow);
}
.section-title span {
  display: inline-block;
  font-size: 0.75em;
  font-weight: 200;
  color: var(--grey3);
  text-shadow: none;
}
.single-line.section-title {
  max-width: none;
  white-space: nowrap;
}
.single-line.section-title br {
  display: none;
}
.single-line.section-title > span {
  margin: 0.06em 0.1em 0 0;
  vertical-align: top;
}
/*=============================================
=                  MEDIA QUERIES             =
=============================================*/
@media screen and (max-width: 1440px) {
  .section-title {
    font-size: 4rem;
  }
}
@media screen and (max-width: 1200px) {
  .section-title {
    font-size: 3.75rem;
  }
}
@media screen and (max-width: 1024px) {
  .section-title {
    font-size: 3.5rem;
  }
}
@media screen and (max-width: 991px) {
  .section-title {
    font-size: 3rem;
  }
  .single-line.section-title > span {
    margin-top: 0.08em;
  }
}
@media screen and (max-width: 767px) {
  .section-title {
    font-size: 2.8rem;
    margin-bottom: 0.6em;
  }
}
@media screen and (max-width: 420px) {
  .section-title {
    font-size: 2.5rem;
  }
}
</style>
