<template>
  <div class="section-background fixed part-component">
    <div
      v-if="mask"
      ref="mask"
      class="section-nav-mask overlay layout-column"
      :style="{ opacity: maskOpacity }"
    >
      <div class="section-header-mask"></div>
      <div class="section-navigation-mask"></div>
      <div class="section-background-mask"></div>
      <div class="section-footer-mask"></div>
    </div>

    <div class="section-background-img overlay">
      <img
        :src="image"
        :style="imageCss"
        ref="img"
        data-depth="1"
        class="parallax-item"
      />
    </div>
  </div>
</template>

<script>
import { partComponent } from "@/mixins/core.js";

export default {
  name: "BackgroundImageFull",
  mixins: [partComponent],
  props: ["image", "imageCss", "mask", "maskOpacity"],
  data() {
    return {};
  },
  methods: {
    createTimeline() {
      const { mask, img } = this.$refs;

      //enter timeline
      const tl = new TimelineMax({
        paused: true,
      });

      tl.from(
        img,
        2.8,
        {
          autoAlpha: 0,
          ease: Power1.easeOut,
        },
        0
      )
        .from(
          img,
          4.5,
          {
            y: "+=2%",
            ease: Power3.easeOut,
          },
          0
        )
        .from(
          img,
          5.5,
          {
            scale: "1.2",
            ease: Power3.easeOut,
          },
          0
        );

      if (this.mask) {
        tl.from(
          mask,
          5.5,
          {
            autoAlpha: 0,
            ease: Power0.noEase,
          },
          0
        );
      }

      this.timeline = tl;
    },
  },
};
</script>

<style scoped>
.section-background-img img {
  width: 110%;
  height: 110%;
  object-fit: cover;
  object-position: center center;
  position: absolute;
  top: auto !important;
  left: auto !important;
}
.overlay {
  width: 102%;
  left: -1%;
}
</style>
