<template>
  <div :id="viewCSSId" :class="viewCSSClasses" ref="scene">
    <div class="section-foreground container">
      <div class="layout-row cols">
        <div class="col-1 layout-column layout-align-start-end col-padding">
          <section-title
            :partNo="part.id"
            :partTitle="part.title"
            data-depth="2.5"
            class="text-right parallax-item parallax-relative"
            :show="showTitle"
          ></section-title>
          <lead
            id="lead-enquire"
            :show="showLead"
            class="parallax-item parallax-relative"
            data-depth="1.8"
            :textAlign="'right'"
            :timescale="1"
            >{{ data.meta.description }}</lead
          >
        </div>
        <div class="col col-2 layout-align-start-start col-padding">
          <form-enquire
            :show="showDiagram"
            :image="data.images.frog"
            :messages="{
              msg_success: data.msg_success,
              msg_error: data.msg_error,
            }"
            class="parallax-relative parallax-item"
            data-depth="1.8"
          ></form-enquire>
        </div>
      </div>
    </div>

    <background-image-full
      :image="data.images.background"
      :show="showBackground"
      :imageCss="{ objectPosition: 'bottom center' }"
      :mask="true"
      :maskOpacity="0.9"
      :timescaleReverse="2.5"
      :timescale="1.17"
    ></background-image-full>

    <div class="container fixed">
      <decal
        id="decal-bateleur"
        class="absolute"
        :show="showDecal"
        :image="data.images.decal"
        :parallaxDepth="1.5"
        :customTimeline="timelines.timelineBateleur"
      ></decal>
    </div>

    <svg class="defs-only">
      <filter id="filter-enquire">
        <feColorMatrix
          type="matrix"
          values=".33 .33 .33 0 0
              .33 .33 .33 0 0
              .33 .33 .33 0 0
              0 0 0 1 0"
        />

        <feComponentTransfer color-interpolation-filters="sRGB">
          <feFuncR type="table" tableValues="0.37 0.67 1" />
          <feFuncG type="table" tableValues="0.37 0.74 1" />
          <feFuncB type="table" tableValues="0.37 0.82 1" />
        </feComponentTransfer>
      </filter>
    </svg>
  </div>
</template>

<script>
import { chapterPart } from "@/mixins/core.js";

import SectionTitle from "@/components/layout/SectionTitle.vue";
import Lead from "@/components/ui/Lead.vue";
import FormEnquire from "@/views/enquire/FormEnquire.vue";
import BackgroundImageFull from "@/components/ui/BackgroundImageFull.vue";
import Decal from "@/components/ui/Decal.vue";

export default {
  name: "EnquireForm",
  metaInfo() {
    return {
      title: "Enquire Now",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.data.meta.description,
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: `${this.$urls.buildUrl}` + window.location.pathname,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: "Enquire Now | Londolozi Impact",
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.data.meta.description,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: `${this.$urls.img}/opengraph/enquire.jpg`,
        },
      ],
    };
  },
  mixins: [chapterPart],
  components: {
    SectionTitle,
    Lead,
    FormEnquire,
    BackgroundImageFull,
    Decal,
  },
  data() {
    return {
      doParallax: true,
      itemsToShow: [
        ["showDelay", 0],
        ["showBackground", 1400],
        ["showTitle", 200],
        ["showLead", 0],
        ["showDiagram", 0],
        ["showDecal", 1650],
      ],
      timelines: {
        timelineBateleur: {
          duration: 0.8,
          animations: {
            ease: Power1.easeInOut,
            autoAlpha: 0,
            x: "-=30%",
            scale: "1",
          },
        },
      },
    };
  },
};
</script>

<style scoped>
.view-part {
  min-height: 100%;
}

.col-1 {
  padding-right: 1%;
  margin-left: auto;
}
.col-2 {
  max-width: 43.75em;
  margin-right: auto;
}
.col-1,
.col-2 {
  padding-top: 2.5vh;
}
.part-form {
  position: relative;
  width: 100%;
  max-width: 40em;
  background: rgba(255, 255, 255, 0.65);
  padding: 1.5rem 0 1.5rem;
  border: 3px solid rgba(0, 0, 0, 0.05);
}

.section-title {
  margin-top: 54%;
}

#decal-bateleur {
  margin: 0;
  top: 14%;
  left: 2%;
}

/deep/ #decal-bateleur img {
  width: 280px;
  max-width: 15vw;
}

/deep/ .section-title br {
  display: none;
}

/deep/ .lead {
  max-width: 13em;
}

/deep/ .section-background img {
  filter: url(#filter-enquire);
}

/deep/ .section-background-mask {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.5) 30%,
    rgba(255, 255, 255, 0.1) 80%,
    rgba(255, 255, 255, 0.1) 100%
  );
}
/deep/ .section-nav-mask .section-footer-mask {
  background: rgba(255, 255, 255, 0.1);
}
/*=============================================
=                  MEDIA QUERIES             =
=============================================*/

@media screen and (max-width: 1440px) {
  #decal-bateleur {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  .cols {
    flex-direction: column;
  }
  .cols > * {
    width: 100%;
    padding: var(--col-padding);
    align-items: center;
    justify-content: center;
    margin: 0 0 2rem;
    max-width: 100%;
  }
  .section-title {
    margin-top: 0.378em;
  }
  /deep/ .lead-wrap .lead-line {
    left: -2px;
    right: auto;
  }
  /deep/ .lead {
    max-width: 18em;
    text-align: left;
  }
}
</style>
