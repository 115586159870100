<template>
  <div class="part-component section-decal" ref="image">
    <media-caption
      class="decal-caption parallax-item parallax-relative"
      v-if="caption"
      :data-depth="parallaxDepth * 0.8"
    >
      <slot name="desc"> </slot>
      <template v-slot:desc>
        <slot name="desc">
          {{ caption.desc }}
        </slot>
      </template>
      <template v-slot:sub>
        <slot name="sub">
          <span v-html="caption.sub"></span>
        </slot>
      </template>
    </media-caption>
    <img
      class="parallax-item parallax-relative"
      :src="image"
      :data-depth="parallaxDepth"
    />
  </div>
</template>

<script>
import { partComponent } from "@/mixins/core.js";

import MediaCaption from "@/components/ui/MediaCaption.vue";

export default {
  name: "Decal",
  components: {
    MediaCaption,
  },
  mixins: [partComponent],
  props: {
    image: {
      type: String,
    },
    caption: {
      type: Object,
    },
    parallaxDepth: {
      type: Number,
      default: 2,
    },
    customTimeline: {
      type: Object,
      default: function () {
        return {
          duration: 2,
          animations: {
            ease: Power1.easeIn,
            autoAlpha: 0,
          },
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    createTimeline() {
      const { image } = this.$refs;

      const tl = new TimelineMax({
        paused: true,
      });

      tl.from(
        image,
        this.customTimeline.duration,
        this.customTimeline.animations,
        0
      );

      if (this.caption) {
        tl.from(
          ".decal-caption",
          0.7,
          {
            autoAlpha: 0,
            y: "+=20",
            ease: Power2.easeIn,
          },
          "-=1.2"
        );
      }

      this.timeline = tl;
    },
  },
};
</script>

<style scoped>
.section-decal {
  z-index: 2;
  -ms-user-select: none;
  user-select: none;
  margin: auto 0 0.5rem 0;
  overflow: hidden;
}
.section-decal img {
  max-width: 100%;
  display: block;
}
.decal-caption {
  margin: 3rem 0 3rem 1rem;
}
</style>
