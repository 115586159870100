<template>
  <div
    class="part-component part-lead"
    ref="lead"
    :class="`lead-align-${textAlign}`"
  >
    <h3
      v-if="heading"
      class="lead-heading h3 parallax-relative parallax-item"
      data-depth="0.4"
    >
      {{ heading }}
    </h3>
    <div class="lead-wrap parallax-relative parallax-item" data-depth="0.2">
      <p class="lead">
        <slot></slot>
      </p>
      <div class="lead-line" ref="line"></div>
    </div>
  </div>
</template>

<script>
import { partComponent } from "@/mixins/core.js";

export default {
  name: "Lead",
  mixins: [partComponent],
  props: {
    show: Boolean,
    heading: String,
    textAlign: {
      type: String,
      default: "left",
    },
    customHeadingTimeline: {
      type: Object,
      default: function () {
        return {
          start: 0.4,
          duration: 0.6,
          animations: {
            autoAlpha: 0,
            y: "+=10",
            ease: Power1.easeIn,
          },
        };
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    createTimeline() {
      const { lead, line } = this.$refs;

      let direction = this.textAlign == "right" ? "+=40" : "-=40";

      const tl = new TimelineMax({
        paused: true,
      });

      tl.from(
        lead,
        0.7,
        {
          autoAlpha: 0,
          x: direction,
          ease: Power1.easeInOut,
        },
        0
      )
        .from(
          line,
          0.7,
          {
            x: direction,
            ease: Power1.easeInOut,
          },
          0
        )
        .from(
          line,
          0.4,
          {
            height: 0,
            ease: Power2.easeOut,
          },
          "+=.1"
        );

      if (this.heading) {
        tl.from(
          ".lead-heading",
          this.customHeadingTimeline.duration,
          this.customHeadingTimeline.animations,
          this.customHeadingTimeline.start
        );
      }

      this.timeline = tl;
    },
  },
};
</script>

<style scoped>
.lead-align-right .lead {
  padding-left: 0;
  padding-right: 1.13em;
}
.lead-align-right .lead-line {
  left: auto;
  right: -2px;
}
.lead-heading {
  font-weight: 400;
  padding-left: 0.662em;
  margin: 0 0 0.483em;
}
.lead-align-right {
  text-align: right;
}
.lead-align-right .lead-heading {
  padding-right: 0.662em;
  padding-left: 0;
}
</style>
