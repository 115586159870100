<template>
  <div
    class="part-component part-form form"
    :class="{ 'form-loading': status.working }"
  >
    <transition name="slide">
      <div
        v-if="status.complete"
        class="form-response layout-column layout-align-center-center"
      >
        <div
          class="form-message layout-align-center-center layout-column"
          v-if="status.success"
        >
          <div class="h3">Thank you for your enquiry</div>
          <span v-html="messages.msg_success"></span>
        </div>
        <div
          class="form-message layout-align-center-center layout-column"
          v-if="status.fail"
        >
          <div class="h3">Something went wrong...</div>
          <span v-html="messages.msg_error"></span>
        </div>
        <div class="form-decal">
          <img :src="image" />
        </div>
      </div>
    </transition>
    <div
      class="
        form-working
        layout-align-center-center layout-column
        absolute
        width-100
        height-100
        italic
      "
    >
      <div class="spinner">
        <div></div>
      </div>
      <span>One moment...</span>
    </div>
    <form-wizard
      v-if="!status.complete"
      @on-complete="onComplete"
      error-color="#a94442"
      ref="formWiz"
      :class="{ 'no-select': status.working }"
      finishButtonText="Send Enquiry"
      color="#e7e7e7"
    >
      <tab-content
        title="Your journey"
        icon="ai-rhino"
        :before-change="validateFirstTab"
      >
        <vue-form-generator
          :model="model"
          :schema="bookingOrRetreatSchema"
          :options="formOptions"
          ref="bookingOrRetreatForm"
        ></vue-form-generator>
        <transition-group name="slide" mode="out-in">
          <vue-form-generator
            v-show="!isRetreat"
            :key="'tripForm'"
            :model="model"
            :schema="tripSchema"
            :options="formOptions"
            ref="tripForm"
          ></vue-form-generator>
          <vue-form-generator
            v-show="isRetreat"
            :key="'retreatForm'"
            :model="model"
            :schema="retreatSchema"
            :options="formOptions"
            ref="retreatForm"
          ></vue-form-generator>
        </transition-group>
      </tab-content>
      <tab-content
        title="Your info"
        icon="ai-footprints"
        :before-change="validateSecondTab"
      >
        <vue-form-generator
          :model="model"
          :schema="contactSchema"
          :options="formOptions"
          ref="contactForm"
        ></vue-form-generator>
      </tab-content>
      <tab-content
        title="Extra info"
        icon="ai-binoculars"
        :before-change="validateThirdTab"
      >
        <vue-form-generator
          :model="model"
          :schema="extraSchema"
          :options="formOptions"
          ref="extraForm"
        ></vue-form-generator>
      </tab-content>
    </form-wizard>
    <button @click="status.working = !status.working">working</button>
    <button @click="completeForm(true)">fail</button>
    <button @click="completeForm(false)">success</button>
  </div>
</template>

<script>
import axios from "axios";
import VueAxios from "vue-axios";
import qs from "qs";

import { partComponent } from "@/mixins/core.js";

import moment from "moment";
import datepicker from "js-datepicker";
import "js-datepicker/dist/datepicker.min.css";

import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css";

import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  name: "FormEnquire",
  components: {
    FormWizard,
    TabContent,
    "vue-form-generator": VueFormGenerator.component,
  },
  mixins: [partComponent],
  props: ["image", "messages"],
  computed: {
    isRetreat() {
      return this.model.interested_in == "Booking" ? false : true;
    },
  },
  data() {
    return {
      datepickerOptions: {},
      token: "fa89egavbaweagnt3ew8t0iwz9hdmuhntbbp6uic",
      status: {
        complete: false,
        fail: false,
        success: false,
        working: false,
      },
      model: {
        interested_in: "Booking",
        flexible_dates: false,
        retreat: [],
        retreat_year: "",
        retreat_adults: "",
        arrival_date: "",
        arrival_date_dummy: "",
        departure_date: "",
        departure_date_dummy: "",
        year: "",
        month: "",
        how_long: "",
        adults: "",
        children: "",
        children_number: "",
        camps: [],
        tour: "",
        first_name: "",
        last_name: "",
        email: "",
        contact_number: "",
        travel_agent: false,
        visited_before: false,
        enquiry: "",
        Subscription_details: [],
      },
      formOptions: {
        validationErrorClass: "has-error",
        validationSuccessClass: "has-success",
        validateAfterChanged: true,
      },
      bookingOrRetreatSchema: {
        fields: [
          {
            type: "radios",
            label: "What are you interested in?",
            model: "interested_in",
            values: ["Booking", "Retreat"],
          },
        ],
      },
      retreatSchema: {
        fields: [
          {
            type: "checklist",
            label: "Which retreat(s) are you interested in",
            model: "retreat",
            listBox: true,
            required: true,
            validator: this.atLeastOne,
            values: [
              "Londolozi Healing House & Wilderness",
              "Track Your Life",
              "S.T.A.R",
            ],
          },
          {
            type: "select",
            inputType: "text",
            label: "Year",
            model: "retreat_year",
            validator: VueFormGenerator.validators.string,
            values: function () {
              let years = [];
              years.push(moment().format("YYYY"));
              years.push(moment().add(1, "year").format("YYYY"));
              years.push(moment().add(2, "year").format("YYYY"));
              return years;
            },
            required: true,
          },
          {
            type: "input",
            inputType: "number",
            label: "Number of adults",
            model: "retreat_adults",
            required: true,
            validator: VueFormGenerator.validators.number,
            min: 1,
            max: 50,
          },
        ],
      },
      tripSchema: {
        fields: [
          {
            type: "switch",
            inputType: "text",
            label: "Flexible dates?",
            model: "flexible_dates",
            default: false,
            textOn: "Yes",
            textOff: "No",
          },
          {
            type: "input",
            inputType: "text",
            label: "Arrival date",
            model: "arrival_date_dummy",
            validator: VueFormGenerator.validators.date,
            id: "arrival_date",
            required: true,
            disabled: function (model) {
              return model.flexible_dates;
            },
          },
          {
            type: "input",
            inputType: "text",
            label: "Departure date",
            model: "departure_date_dummy",
            validator: VueFormGenerator.validators.date,
            id: "departure_date",
            required: true,
            disabled: function (model) {
              return model.flexible_dates;
            },
          },
          {
            type: "select",
            inputType: "text",
            model: "year",
            label: "Year",
            validator: VueFormGenerator.validators.string,
            values: function () {
              let years = [];
              years.push(moment().format("YYYY"));
              years.push(moment().add(1, "year").format("YYYY"));
              years.push(moment().add(2, "year").format("YYYY"));
              return years;
            },
            required: true,
            disabled: function (model) {
              return !model.flexible_dates;
            },
          },
          {
            type: "select",
            inputType: "text",
            label: "Month",
            model: "month",
            validator: VueFormGenerator.validators.string,
            values: function () {
              return [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];
            },
            required: true,
            disabled: function (model) {
              return !model.flexible_dates;
            },
          },
          {
            type: "select",
            inputType: "text",
            label: "How long?",
            model: "how_long",
            validator: VueFormGenerator.validators.string,
            values: function () {
              return [
                "Short break",
                "7 nights",
                "10 nights",
                "2 weeks",
                "Longer",
              ];
            },
            required: true,
            disabled: function (model) {
              return !model.flexible_dates;
            },
          },
          {
            type: "input",
            inputType: "number",
            label: "Number of adults",
            model: "adults",
            required: true,
            validator: VueFormGenerator.validators.number,
            min: 1,
            max: 50,
          },
          {
            type: "switch",
            label: "Travelling with children?",
            model: "children",
            default: false,
            textOn: "Yes",
            textOff: "No",
          },
          {
            type: "input",
            inputType: "number",
            label: "Number of children",
            model: "children_number",
            required: true,
            validator: VueFormGenerator.validators.number,
            min: 1,
            max: 10,
            disabled: function (model) {
              return !model.children;
            },
          },
          {
            type: "checklist",
            label: "Which camp(s) would you like to stay at?",
            model: "camps",
            listBox: true,
            required: true,
            validator: this.atLeastOne,
            values: [
              "Tree Camp",
              "Pioneer Camp",
              "Varty Camp",
              "Founders Camp",
              "Granite Suites",
            ],
          },
          {
            type: "select",
            inputType: "text",
            model: "tour",
            label: "Would you like to go on a tour?",
            validator: VueFormGenerator.validators.string,
            values: function () {
              return [
                "I only want to stay at Londolozi",
                "A Tailor-Made Itinerary",
                "Cape Town - Kruger Park - Victoria Falls",
                "Cape Town - Kruger Park - Tswalu",
                "Kruger Park - Victoria Falls - Botswana",
                "Kruger Park - Bazaruto",
                "Kruger Park - Quirimbas",
              ];
            },
            required: true,
          },
        ],
      },
      contactSchema: {
        fields: [
          {
            type: "input",
            inputType: "text",
            label: "First name",
            model: "first_name",
            required: true,
            validator: VueFormGenerator.validators.string,
          },
          {
            type: "input",
            inputType: "text",
            label: "Last name",
            model: "last_name",
            required: true,
            validator: VueFormGenerator.validators.string,
          },
          {
            type: "input",
            inputType: "text",
            label: "Email",
            model: "email",
            required: true,
            validator: VueFormGenerator.validators.email,
          },
          {
            type: "input",
            label: "Telephone",
            inputType: "tel",
            model: "contact_number",
            required: true,
            validator: VueFormGenerator.validators.string,
          },
        ],
      },
      extraSchema: {
        fields: [
          {
            type: "switch",
            label: "Are you working with a travel agent?",
            model: "travel_agent",
            textOn: "Yes",
            textOff: "No",
          },
          {
            type: "switch",
            label: "Have you been to Londolozi before?",
            model: "visited_before",
            textOn: "Yes",
            textOff: "No",
          },
          {
            type: "textArea",
            label: "Is there anything else we should know?",
            placeholder:
              "Tell us about any special requirements you might have",
            model: "enquiry",
            max: 1500,
            rows: 2,
            validator: VueFormGenerator.validators.string,
          },
          {
            type: "checklist",
            label: "Subscribe to our newsletter",
            model: "Subscription_details",
            listBox: true,
            values: ["daily", "monthly"],
          },
        ],
      },
    };
  },
  methods: {
    atLeastOne(value) {
      return value.length ? [] : ["Select at least one option"];
    },
    prepareFormDataForAPI(data) {
      //add token
      data.token = this.token;

      //add gofetch datavault
      data.vault_name = "impact_enquiry_traveller";
      data.origin = "impact_enquiry_traveller";

      //arrays to string
      if (data.camps) {
        data.camps = data.camps.toString();
      }
      if (data.retreat) {
        data.retreat = data.retreat.toString();
      }

      if (data.interested_in === "Booking") {
        delete data["retreat"];
        delete data["retreat_year"];
        delete data["retreat_adults"];
        if (data.flexible_dates) {
          delete data["arrival_date"];
          delete data["departure_date"];
        } else {
          delete data["year"];
          delete data["month"];
          delete data["how_long"];
        }
        if (!data.children) {
          delete data["children_number"];
        }
      } else if (data.interested_in === "Retreat") {
        delete data["flexible_dates"];
        delete data["arrival_date"];
        delete data["departure_date"];
        delete data["year"];
        delete data["month"];
        delete data["how_long"];
        delete data["adults"];
        delete data["children"];
        delete data["children_number"];
        delete data["camps"];
        delete data["tour"];
      }

      for (var propName in data) {
        if (
          data[propName] === null ||
          data[propName] === undefined ||
          data[propName] === ""
        ) {
          delete data[propName];
        }
      }

      return data;
    },
    onComplete: function () {
      this.status.working = true;

      const data = { ...this.model };
      //basic data sanitize
      delete data.arrival_date_dummy;
      delete data.departure_date_dummy;

      //post data to shaprspring
      const sharpSpringData = Object.assign({}, data);
      this.postDataToSharpSpring(sharpSpringData);

      //prepare data for api and post
      const apiData = this.prepareFormDataForAPI(Object.assign({}, data));
      this.postData(apiData);
    },
    postDataToSharpSpring(data) {
      //add trackingID
      if (window._ss.trackingID) {
        console.log(
          "adding tracking id to axios post data " + window._ss.trackingID
        );
        data.trackingid__sb = window._ss.trackingID;
      }

      //create axios instance with qs to format for querystring
      let sharpspringAxios = axios.create({
        paramsSerializer: (params) =>
          qs.stringify(params, { arrayFormat: "comma" }),
      });
      //define url
      let url =
        this.$sharpspring.forms.enquiry_traveller.baseURI +
        this.$sharpspring.forms.enquiry_traveller.endpoint +
        "/jsonp/";

      sharpspringAxios
        .get(url, {
          params: data,
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    postData(data) {
      axios
        .post(
          "https://blog.londolozi.com/wp-json/wp/v2/enquiry/booking",
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          if (response.data.success) {
            this.completeForm(false);
          } else {
            this.completeForm(true);
          }
        })
        .catch((error) => {
          console.log(error);
          this.completeForm(true);
        });
    },
    completeForm(errors) {
      this.status.working = false;
      if (errors) {
        this.status.fail = true;
        this.status.success = false;
      } else {
        this.status.fail = false;
        this.status.success = true;
      }
      this.status.complete = true;
    },
    validateFirstTab: function () {
      return this.isRetreat
        ? this.$refs.retreatForm.validate()
        : this.$refs.tripForm.validate();
    },
    validateSecondTab: function () {
      return this.$refs.contactForm.validate();
    },
    validateThirdTab: function () {
      return this.$refs.extraForm.validate();
    },
    createTimeline() {
      const tl = new TimelineMax({
        paused: true,
      });

      tl.from(".part-form", 0.8, {
        autoAlpha: 0,
        ease: Power1.easeInOut,
        x: "+=50",
      });

      this.timeline = tl;
    },
  },
  mounted() {
    const dateFields = ["arrival_date", "departure_date"];
    const pickers = {};
    const now = moment().toDate();

    dateFields.forEach((element, i) => {
      let childNode = i + 1;

      pickers[element] = datepicker(`#${element}`, {
        minDate: now,
        onSelect: (instance, date) => {
          let niceData = moment(date).format("YYYY-MM-DD");
          this.model[element] = niceData;
          this.$refs.tripForm.$children[childNode].validate();
        },
        formatter: (input, date, instance) => {
          // This will display the date as `1/1/2019`.
          let m = moment(date).format("YYYY-MM-DD");
          input.value = m;
          this.model[`${element}_dummy`] = m;
        },
      });
    });
  },
};
</script>

<style scoped>
.part-form button {
  display: none;
}
.form-response {
  padding: 1rem 1.2rem 2rem;
}
.form-message {
  max-width: 400px;
}
.form-response .h3 {
  padding: 0.5em 0 1.1em;
  margin: 0 0 0.85em;
  position: relative;
  font-weight: normal;
  font-size: 1.818rem;
  text-align: center;
}
.form-response .h3::after {
  content: "";
  background: var(--blue);
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 23%;
  height: 3px;
  position: absolute;
}

.form-response p {
  text-align: center;
  margin: 0 0 2em;
}
</style>
